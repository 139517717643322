<template>
  <div>
    <w-card>
      <w-card-section title="Mini-programs management">
        <div v-html="$t('MINI_PROGRAM.TIPS')"></div>
        <template #buttons>
          <w-button primary plain to="/mini-programs">
            Manage mini-programs
            <template #rightIcon>
              <w-icon file="arrow-right" size="4" />
            </template>
          </w-button>
        </template>
      </w-card-section>
    </w-card>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class Home extends Vue {

  @State(state => state.auth.user) user;

  mounted() {
    if(!this.user) {
      this.$router.push('/auth/superadmin/login')
    }
  }
}
</script>
